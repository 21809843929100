import PageHeader from 'components/SectionHeaders/PageHeader';
import Loading from 'components/Layout/Loading';
import TerritoryPrepConfirmationModal from './_components/TerritoryPrepConfirmationModal';
import useTerritoryPrep from './_hookStore/useTerritoryPrep';
import { Alert, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { createEmptyTerritoryPrepForm, ITerritoryPrepForm } from 'model/territory_prep/TerritoryPrepForm';
import TerritoryPrepForm from './TerritoryPrepForm';

export default function TerritoryPrep() {
  const { loadingKey } = useTerritoryPrep();
  const formContext = useForm<ITerritoryPrepForm>({ mode: 'onTouched', defaultValues: createEmptyTerritoryPrepForm() });

  return (
    <Loading loadingKey={loadingKey}>
      <Grid padding={'25px'}>
        <PageHeader headerText={'Territory Prep'} />
        <Alert severity='info'>
          Prep territory to reconcile NSOs, update household count, and seed customers for all neighborhoods in the territory.
        </Alert>
        <FormProvider {...formContext}>
          <TerritoryPrepForm/>
        </FormProvider>
        <TerritoryPrepConfirmationModal />
      </Grid>
    </Loading>
  );
}