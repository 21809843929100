import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import React, { useState } from 'react';
import NeighborhoodCombiner from './neighborhoodCombiner/NeighborhoodCombiner';
import NeighborhoodSplitter from './neighborhoodSplitter/NeighborhoodSplitter';
import TooltipDrawerButton from 'components/drawer/TooltipDrawerButton';
import TooltipDrawer from 'components/drawer/TooltipDrawer';


export default function NeighborhoodRestructuring() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1} sx={{ padding: '10px' }}>
      <Grid item xs={12}>
        <PageHeader headerText={'Neighborhood Restructuring'}
          rightChildren={
            <TooltipDrawerButton
              storeKey='neighborhoodRestructuring'
              iconButtonProps={{ sx: { marginTop: '20px' } }}
            />
          }/>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Combine Neighborhoods" />
          <Tab label="Split Neighborhood" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {value === 0 && <NeighborhoodCombiner />}
        {value === 1 && <NeighborhoodSplitter/>}
      </Grid>
      <TooltipDrawer storeKey={'neighborhoodRestructuring'}>
        <Box sx={{ padding: '16px', maxWidth: '400px' }}>
          <Typography variant='h6'>Neighborhood Combination</Typography>
          <Typography variant='body1'>Choose which neighborhood will absorb the data from the subsumed ones.
            As you select subsumed neighborhoods you will see their polygons appear on the google map below.</Typography>
          <Typography variant='body1'>
            <b>Steps on backend</b>
            <ul>
              <li>Run territory prep for the Consolidating neighborhood.</li>
              <li>For each subsumed neighborhood we will transfer data to the Consolidating neighborhood in any of the following:
                individuals (users), customers, subscribers, reviews, nsois, letter batch neighborhoods (campaigns), and neighborhood exclusions for coverage areas</li>
              <li>After initial data transfer is complete we will want to delete any existing NSOs and Service Summary data for the subsumed neighborhoods. To do this we will update
                the neighborhood with a WKT in the middle of the Atlantic Ocean and then run territory prep. </li>
              <li>Next we soft delete the subsumed neighborhoods in dynamo and postgres</li>
              <li>Finally we run service summary sync to get the new data into the neighborhood</li>
            </ul>
          </Typography>
          <Box sx={{ height: '40px' }}></Box>
          <Typography variant='h6'>Neighborhood Split</Typography>
          <Typography variant='body1'>Choose the neighborhood that will be split. You may have the split neighborhood in both inputs if you still need to keep it around.</Typography>
          <Typography variant='body1'>
            <b>Steps on backend</b>
            <ul>
              <li>Get all individuals from dynamodb in the original neighborhood. If any individuals exist then we can't split the neighborhood.</li>
              <li>For each split neighborhood:
                <ol>
                  <li>we run a territory prep for nsos, household count, and seeding</li>
                  <li>transfer any necessary data. The initial implementation of this still exists so it will attempt to transfer any customers, subscribers, etc. For now--early 2025--
                    we should <i>not</i> split any live neighborhoods due to complexity in assignment of data (need to geomatch most data) and deficiencies in data consistency (e.g. don't always have address of customer).
                  </li>
                </ol>
              </li>
            </ul>
          </Typography>
        </Box>
      </TooltipDrawer>
    </Grid>
  );
}