import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import { prepTerritory } from 'api/neighborhoodLaunchApi';
import { searchByTerritory } from 'api/neighborhoodApi';
import { INeighborhood } from 'model/neighborhood';
import { convertTerritoryPrepFormToDTO, ITerritoryPrepForm } from 'model/territory_prep/TerritoryPrepForm';

const loadingKey = 'TerritoryPrep';
type TerritoryPrep = {
  selectedTerritory: IDropdownOption | null;
  territoryNeighborhoods: INeighborhood[];
}

const { get, update, registerListener, unregisterListener } = createStore<TerritoryPrep>('TerritoryPrep', {
  selectedTerritory: null,
  territoryNeighborhoods: [],
});

export default function useTerritoryPrep() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createErrorToast, createSuccessToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      update({
        ...get(),
        selectedTerritory: null,
        territoryNeighborhoods: [],
      });
    };
  }, []);

  async function onTerritorySelect(territorySelection: IDropdownOption | null, formContext: any) {
    try {
      onLoading();

      if (territorySelection) {
        let dto = {
          zipCodeTerritory: territorySelection?.ancillary.zipCodeTerritory,
        };
        let marketId = territorySelection?.ancillary.marketId;
        let territoryNeighborhoodsRes = await searchByTerritory(marketId, dto);
        let territoryNeighborhoods: INeighborhood[] = [];
        if (territoryNeighborhoodsRes.data) {
          formContext.setValue('territoryNeighborhoods', []);
          territoryNeighborhoods = territoryNeighborhoodsRes.data;
          formContext.setValue('territoryNeighborhoods', territoryNeighborhoodsRes.data?.map(x => ({
            ...x,
            checked: true,
          })));
        } else {
          formContext.setValue('territoryNeighborhoods', []);
        }
        update({
          ...get(),
          selectedTerritory: territorySelection,
          territoryNeighborhoods,
        });
      } else {
        formContext.setValue('territoryNeighborhoods', []);
        update({
          ...get(),
          selectedTerritory: null,
          territoryNeighborhoods: [],
        });
      }

      doneLoading(300);
    } catch (e) {
      console.error(e);
    }
  }

  async function onConfirmTerritoryPrep(data: ITerritoryPrepForm) {
    onLoading();
    try {
      const { selectedTerritory } = get();
      var dto = convertTerritoryPrepFormToDTO(data);
      await prepTerritory(selectedTerritory!.optionValue, dto);
      createSuccessToast('Successfully initiated territory prep process!');
    } catch (e : any) {
      console.error(e);
      createErrorToast(`Error prepping territory: ${e.response?.data?.message}`);
    }
    doneLoading(300);
  }

  function validate(formData:ITerritoryPrepForm) {
    const territory = get().selectedTerritory;
    if (!territory || !territory.optionValue) {
      createErrorToast('Please select a territory');
      doneLoading(300);
      return false;
    }
    if (formData.territoryNeighborhoods.length === 0) {
      createErrorToast('Please select at least one neighborhood');
      doneLoading(300);
      return false;
    }
    if (formData.territoryNeighborhoods.filter(x => x && x.checked).length === 0) {
      createErrorToast('Please select at least one neighborhood');
      doneLoading(300);
      return false;
    }

    return true;
  }

  return {
    ...get(),
    onConfirmTerritoryPrep,
    onTerritorySelect,
    loadingKey,
    validate,
  };
}