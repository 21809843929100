import { PrimaryButton } from 'components/buttons';
import React from 'react';
import useNeighborhoodSplitter from './useNeighborhoodSplitter';


const SubmitButton: React.FC = () => {
  const { onSubmit } = useNeighborhoodSplitter();
  return (
    <PrimaryButton onClick={onSubmit} >
        Submit
    </PrimaryButton>
  );
};

export default SubmitButton;