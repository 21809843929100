import { NeighborhoodOverlapMap } from 'components/gmaps';
import useNeighborhoodCombiner from './useNeighborhoodCombiner';
import { useEffect } from 'react';
import { findBounds, findCenter } from 'components/gmaps/util';

export default function NeighborhoodMapDisplay() {
  const {
    combinedNeighborhoodId,
    combinedNeighborhoodGeoJson,
    onCombinedNeighborhoodChange,
    subsumedNeighborhoodIds,
    subsumedNeighborhoodGeoJson,
    onSubsumedNeighborhoodChange,
  } = useNeighborhoodCombiner();

  useEffect(() => {
    if (combinedNeighborhoodId) {
      void onCombinedNeighborhoodChange(combinedNeighborhoodId!);
    }
  }, [combinedNeighborhoodId]);

  useEffect(() => {
    if (subsumedNeighborhoodIds) {
      void onSubsumedNeighborhoodChange(subsumedNeighborhoodIds);
    }
  }, [subsumedNeighborhoodIds]);


  if (!combinedNeighborhoodGeoJson) {
    return null;
  }
  const bounds = findBounds(combinedNeighborhoodGeoJson);
  const center = findCenter(bounds);
  return (

    <NeighborhoodOverlapMap
      bounds={bounds}
      center={center}
      geoJson={combinedNeighborhoodGeoJson}
      neighborhoods={subsumedNeighborhoodGeoJson}
      zoom={20}
    />

  );
}