import useTerritoryPrep from './_hookStore/useTerritoryPrep';
import { Grid } from '@mui/material';
import SelectedNeighborhoodsSet from './_components/SelectedNeighborhoodsSet';
import TerritorySelect from './_components/TerritorySelect';
import TerritoryPrepSubmitButton from './_components/TerritoryPrepSubmitButton';

export default function TerritoryPrepForm() {
  const { selectedTerritory } = useTerritoryPrep();
  return (
    <Grid container flexDirection={'row'} alignItems={'center'} columnSpacing={3} paddingTop={'25px'}>
      <Grid item xs={3}>
        <TerritorySelect/>
      </Grid>
      <Grid item xs={12} sx={{ padding: '10px' }}>
        <SelectedNeighborhoodsSet/>
      </Grid>
      <Grid item xs={2} sx={{ marginTop: '20px' }}>
        <TerritoryPrepSubmitButton/>
      </Grid>
    </Grid>
  );
}