import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import useTerritoryPrep from '../_hookStore/useTerritoryPrep';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HookCheckbox } from 'components/reactHookForm';

export default function SelectedNeighborhoodsSet() {
  const { selectedTerritory } = useTerritoryPrep();
  const formContext = useFormContext();

  const { fields, replace } = useFieldArray({
    control: formContext.control,
    name: 'territoryNeighborhoods',
  });
  if (!selectedTerritory) {
    return null;
  }
  return (
    <Grid container item xs={12}>
      <Grid item xs='auto'>
        <Typography variant='subtitle1'
          onClick={() => {
            let territoryNeighborhoods = formContext.getValues('territoryNeighborhoods');
            formContext.setValue('territoryNeighborhoods', territoryNeighborhoods.map(x => ({
              ...x,
              checked: true,
            })));
          }}
          sx={{
            'fontWeight': 'bold',
            'color': 'blue',
            'cursor': 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              color: 'teal',
            },
          }}
        >Select all</Typography>
      </Grid>
      <Grid item xs='auto'>
        <Box sx={{ width: '10px' }} />
      </Grid>
      <Grid item xs='auto'>
        <Typography
          onClick={() => {
            let territoryNeighborhoods = formContext.getValues('territoryNeighborhoods');
            formContext.setValue('territoryNeighborhoods', territoryNeighborhoods.map(x => ({
              ...x,
              checked: false,
            })));
          }}
          variant='subtitle1'
          sx={{
            'fontWeight': 'bold',
            'color': 'blue',
            'cursor': 'pointer',
            '&:hover': {
              textDecoration: 'underline',
              color: 'teal',
            },
          }}
        >Deselect all</Typography>
      </Grid>
      <Grid item xs={12}>

      </Grid>
      {
        fields.map((x, i) => (
          <HookCheckbox
            key={x.id}
            name={`territoryNeighborhoods.${i}.checked`}

            label={(x as any).name}
            labelProps={{
              sx: {
                fontSize: '14px',
              },
            }}
          />
        ))
      }
    </Grid>
  );
}