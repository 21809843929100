import { Typography, Grid, Tooltip, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { createInvoiceDescription } from 'model/invoicing';
import { formatUSDTwoDecimal } from 'util/currencyUtil';
import { formatMonthDayYear } from 'util/dateUtil';
import { formatPercentOneDecimal, formatPercentPennyPadding } from 'util/numberUtil';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export function columnsCreator(onGetCustomerInvoice:any):GridColDef[] {
  return [
    {
      field: 'serviceDate',
      headerName: 'Service Date',
      minWidth: 50,
      flex: 1,
      sortable: true,
      valueGetter: (params: any) => {
        if (params.row.recurring) {
          return params.row.serviceStartDate;
        }
        return params.row.localServiceDate;

      },
      renderCell: (params: any) => {

        if (params.row.recurring) {
          return <Typography variant='body2'>Started {formatMonthDayYear(params.row.serviceStartDate)}</Typography>;
        }
        return <Typography variant='body2'>{formatMonthDayYear(params.row.localServiceDate)}</Typography>;

      },
    },
    {
      field: 'neighborhood',
      headerName: 'Neighborhood',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{params.row.neighborhoodName}</Typography>;
      },
    },
    {
      field: 'serviceType',
      headerName: 'Service Type',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{params.row.serviceType}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1,
      renderCell: (params: any) => {
        if (params.row.recurring) {
          return <a target="_blank" rel='noreferrer' href={`${window.REACT_APP_PROVIDER_BASE_URI}/v1/${params.row.providerId}/subscribers/${params.id}`} >{`${params.row.firstName} ${params.row.lastName}`}</a>;
        }
        return <a target="_blank" rel='noreferrer' href={`${window.REACT_APP_PROVIDER_BASE_URI}/v1/${params.row.providerId}/customers/${params.id}`} >{`${params.row.firstName} ${params.row.lastName}`}</a>;
      },
    },

    {
      field: 'totalPrice',
      headerName: 'Price',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{formatUSDTwoDecimal(params.row.totalPrice)}</Typography>;
      },
    },
    {
      field: 'takeRate',
      headerName: 'Take Rate',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{`${formatPercentOneDecimal(params.row.takeRate)}%`}</Typography>;
      },
    },
    {
      field: 'unitQty',
      headerName: 'QTY',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{params.row.unitQuantity}</Typography>;
      },
    },
    {
      field: 'unitPrice',
      headerName: 'Commission',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{formatUSDTwoDecimal(params.row.unitPrice)}</Typography>;
      },
    },
    {
      field: 'totalCommissionAmountToDate',
      headerName: 'Commission To-Date',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{formatUSDTwoDecimal(params.row.totalCommissionAmountToDate)}</Typography>;
      },
    },
    {
      field: 'takeRateCap',
      headerName: 'Commission Cap',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{formatUSDTwoDecimal(params.row.takeRateCap)}</Typography>;
      },
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{formatMonthDayYear(params.row.providerInvoiceDate)}</Typography>;
      },
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice Number',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        return <div>
          {params.row.invoiceUrl && <a href={`${params.row.invoiceUrl}`} target="_blank" rel="noreferrer">{params.row.invoiceNumber}</a>}
        </div>;
      },
    },
    {
      field: 'copyButton',
      headerName: 'Actions',
      minWidth: 50,
      flex: 1,
      renderCell: (params: any) => {
        const copyText = createInvoiceDescription(params.row);
        return (
          <Grid container>
            <Tooltip title='copy invoice item'>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                void navigator.clipboard.writeText(copyText);
              }}>
                <ContentCopyIcon
                  className='copyIcon'
                />
              </IconButton>
            </Tooltip>
            <Tooltip title='get customer invoice number'>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  void onGetCustomerInvoice(params.row.id);
                }}
              >
                <PlagiarismIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];
}