import useMarkets from 'hooks/useMarkets';
import TerritoryAutocomplete from 'components/autocomplete/TerritoryAutocomplete';
import useTerritoryPrep from '../_hookStore/useTerritoryPrep';
import { useFormContext } from 'react-hook-form';

export default function TerritorySelect() {
  const { market } = useMarkets();
  const { onTerritorySelect } = useTerritoryPrep();
  const formContext = useFormContext();
  return (
    <TerritoryAutocomplete
      marketId={market?.id || ''}
      onChangeOverride={async (option:any) => {
        await onTerritorySelect(option, formContext);
      }}
    />
  );
}