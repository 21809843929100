import { PrimaryButton } from 'components/buttons';
import React from 'react';
import useNeighborhoodCombiner from './useNeighborhoodCombiner';


const SubmitButton: React.FC = () => {
  const { onSubmit } = useNeighborhoodCombiner();
  return (
    <PrimaryButton onClick={onSubmit} >
        Submit
    </PrimaryButton>
  );
};

export default SubmitButton;