import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import OriginalNeighborhood from './OriginalNeighborhood';
import SplitNeighborhoods from './SplitNeighborhoods';
import useNeighborhoodSplitter from './useNeighborhoodSplitter';
import SubmitButton from './SubmitButton';

export default function NeighborhoodSplitter() {
  const { loadingKey } = useNeighborhoodSplitter();

  return (
    <Loading loadingKey={loadingKey}>
      <Grid container flexDirection={'row'} alignItems={'center'} columnSpacing={3} padding={'25px'}>
        <Grid item xs={12} md={3}>
          <OriginalNeighborhood/>
        </Grid>

        <Grid item xs={12} md={3}>
          <SplitNeighborhoods/>
        </Grid>
        <Grid item xs={12}>
          <SubmitButton/>
        </Grid>
      </Grid>
    </Loading>
  );
}