import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IServiceTitanForm } from 'model/serviceProviderCrmIntegration';
import { FormProvider, useForm } from 'react-hook-form';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import ServiceTitanFormInputs from './ServiceTitanFormInputs';
import { useEffect } from 'react';

export default function ServiceTitan() {

  const { serviceTitanFormData } = useProviderIntegrationDetails();
  const formContext = useForm<IServiceTitanForm>({ mode: 'onTouched', defaultValues: serviceTitanFormData });

  useEffect(() => {
    formContext.reset(serviceTitanFormData);
  }, [serviceTitanFormData]);

  return (
    <>
      <FormProvider {...formContext}>
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Service Titan</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item>
                <ServiceTitanFormInputs />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </FormProvider>
    </>
  );
}