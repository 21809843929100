import { Grid, Typography } from '@mui/material';
import PageHeader from 'components/SectionHeaders/PageHeader';
import { useParams } from 'react-router-dom';
import Loading, { useLoading } from 'components/Layout/Loading';
import useMarkets from 'hooks/useMarkets';
import useProviderIntegrationDetails from './hookStore/useProviderIntegrationDetails';
import { useEffect } from 'react';
import ServiceTitan from './serviceTitan/ServiceTitan';
import HouseCallProMax from './houseCallProMax/HouseCallProMax';

export default function ProviderIntegrationDetails() {
  const { serviceProviderId } = useParams();

  // const formContext = useForm<IInternalServiceProvider>({ mode: 'onTouched', defaultValues: createEmptyInternalServiceProvider() });
  const { init, loadingKey, serviceProvider } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);

  useEffect(() => {
    void init();
  }, [serviceProviderId]);

  return (
    <>
      <Loading loadingKey={loadingKey} >
        <Grid container spacing={1} className='pageGridContainer'>
          <PageHeader headerText={'Provider Integration Details'} />
          <Grid container item xs={12} columnSpacing={1}>
            <Grid item>
              <Typography variant='body1' sx={{ fontWeight: 'bold' }}>Provider:</Typography>
            </Grid>
            <Grid item paddingBottom={'25px'}>
              <Typography variant='body1'>{serviceProvider?.name}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} paddingBottom={'25px'}>
            <ServiceTitan />
          </Grid>
          <Grid item xs={12} md={7}>
            <HouseCallProMax />
          </Grid>
        </Grid>
      </Loading>
    </>
  );
}