import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HookTextField } from 'components/reactHookForm';
import { IHouseCallProMaxForm, IServiceTitanForm } from 'model/serviceProviderCrmIntegration';
import { FormProvider, useForm } from 'react-hook-form';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import HouseCallProFormInputs from './HouseCallProMaxFormInputs';
import { useEffect } from 'react';

export default function HouseCallProMax() {
  const { houseCallProMaxFormData } = useProviderIntegrationDetails();
  const formContext = useForm<IHouseCallProMaxForm>({ mode: 'onTouched', defaultValues: houseCallProMaxFormData });

  useEffect(() => {
    formContext.reset(houseCallProMaxFormData);
  }, [houseCallProMaxFormData]);

  return (
    <>
      <FormProvider {...formContext}>
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Housecall Pro</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item>
                <HouseCallProFormInputs />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </FormProvider>
    </>
  );
}