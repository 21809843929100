import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { splitNeighborhoods as onSplitNeighborhoods } from 'api/neighborhoodApi';
import { ISplitNeighborhoodRequest } from 'model/neighborhoods/neighborhoodRestructuring';

const loadingKey = 'NeighborhoodSplitter';

type NeighborhoodSplitter = {
  originalNeighborhoodId: string | null;
  splitNeighborhoodIds: string[];
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodSplitter>('NeighborhoodSplitter', {
  originalNeighborhoodId: null,
  splitNeighborhoodIds: [],
});

export default function useNeighborhoodSplitter() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      update({
        ...get(),
        originalNeighborhoodId: null,
        splitNeighborhoodIds: [],
      });
    };
  }, []);

  const setOriginalNeighborhoodId = (neighborhoodId: string | null) => {
    update({
      ...get(),
      originalNeighborhoodId: neighborhoodId,
    });
  };

  const setSplitNeighborhoodIds = (neighborhoodIds: string[]) => {
    update({
      ...get(),
      splitNeighborhoodIds: neighborhoodIds,
    });
  };

  async function onSubmit() {
    try {
      onLoading();
      const { originalNeighborhoodId, splitNeighborhoodIds } = get();
      if (!originalNeighborhoodId || splitNeighborhoodIds.length === 0) {
        createErrorToast('Please select a consolidating neighborhood and at least one subsumed neighborhood.');
        return;
      }
      const request: ISplitNeighborhoodRequest = {
        originalNeighborhoodId: originalNeighborhoodId,
        splitNeighborhoodIds: splitNeighborhoodIds,
      };
      const res = await onSplitNeighborhoods(request);
      if (res.status === 200) {
        createInfoToast('Received request to split neighborhoods');
      }
    } catch (e) {

    } finally {
      doneLoading(300);
    }
  }

  return {
    ...get(),
    setOriginalNeighborhoodId,
    setSplitNeighborhoodIds,
    onSubmit,
    loadingKey,
  };
}