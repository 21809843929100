

import { useMediaQuery, useTheme } from '@mui/material';
import { IGeoJSONCoordinates, IMapBounds } from 'model/maps';
import { IGeoJSONNeighborhood } from 'model/neighborhood';
import { useRef, useEffect, ReactElement } from 'react';
import { OtherNeighborhoodsLayerService } from './services/other_neighborhoods_layer_service';


export interface INeighborhoodOverlapMapComponentProps {
  bounds: IMapBounds;
  center:{lat:number; lng:number};
  zoom:number;
  geoJson:IGeoJSONCoordinates;
  neighborhoods?: IGeoJSONNeighborhood[];
}
function NeighborhoodOverlapMapComponent({ bounds, center, zoom, geoJson, neighborhoods }:INeighborhoodOverlapMapComponentProps) {
  const theme = useTheme();
  const width340 = useMediaQuery(theme.breakpoints.down(340));
  const width380 = useMediaQuery(theme.breakpoints.down(380));
  const smBrk = useMediaQuery(theme.breakpoints.down('sm'));
  const mdBrk = useMediaQuery(theme.breakpoints.down('md'));
  let width, height;

  if (width340) {
    width = '250px';
    height = '250px';
  } else if (width380) {
    width = '300px';
    height = '300px';
  } else if (smBrk) {
    width = '340px';
    height = '340px';
  } else if (mdBrk) {
    width = '450px';
    height = '450px';
  } else {
    width = '600px';
    height = '600px';
  }
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current, {
        // disableDefaultUI: true,
        clickableIcons: false,
        // gestureHandling: 'none',
        center,
        zoom,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [],
            // stylers: [{ visibility: 'off' }],
          },
        ],
      });
      const polygon = new window.google.maps.Polygon({
        paths: geoJson.coordinates.map(x => ({ lat: x.latitude, lng: x.longitude })),
        strokeColor: '#488D5E',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#488D5E',
        fillOpacity: 0.35,
      });
      let padding = 3;
      let _bounds = new window.google.maps.LatLngBounds(bounds.sw, bounds.ne);
      map.fitBounds(_bounds, padding);
      if (neighborhoods) {
        OtherNeighborhoodsLayerService.addNeighborhoods(map, neighborhoods.filter(x => x.geoJson != null && x.geoJson.coordinates.length > 0));
      }
      polygon.setMap(map);
    }
  }, [bounds, center, geoJson, neighborhoods]);

  return <div ref={ref as any} id="neighborhood-overlay-map" style={{ width, height, margin: '0 auto' }}/>;
}

export interface INeighborhoodOverlayMapProps {
  bounds: IMapBounds;
  center: {lat:number; lng: number};
  zoom: number;
  geoJson: IGeoJSONCoordinates;
  neighborhoods?: IGeoJSONNeighborhood[];
}

export default function NeighborhoodOverlapMap({ bounds, center, zoom, geoJson, neighborhoods }:INeighborhoodOverlayMapProps) {
  if (geoJson.coordinates.length === 0) {
    return null;
  }

  return (
    <NeighborhoodOverlapMapComponent bounds={bounds} center={center} zoom={zoom} geoJson={geoJson} neighborhoods={neighborhoods}/>
  );
}