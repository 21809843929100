import { Grid } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import HookTextField from 'components/reactHookForm/HookTextField';
import useProviderIntegrationDetails from '../hookStore/useProviderIntegrationDetails';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { useFormContext } from 'react-hook-form';
import useToast from 'components/toast/useToast';
import { useLoading } from 'components/Layout/Loading';
import { useParams } from 'react-router-dom';
import { saveHCPMaxIntegration } from 'api/serviceProviderApi';

export const houseCallProConfirmationModalKey = 'HouseCallProConfirmationModal';

function HouseCallProConfirmationModal() {
  const { loadingKey } = useProviderIntegrationDetails();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const { createSuccessToast, createErrorToast } = useToast();

  return <HookConfirmationModal
    title={() => 'Save Housecall Pro Configuration'}
    body='Save changes? This will will make the Housecall Pro integration ACTIVE.'
    modalKey={houseCallProConfirmationModalKey}
    message={(confirmArgs, cancelModal) => (
      <Grid container spacing={1} alignItems='center' justifyContent='center' paddingTop={'15px'} >
        <Grid item xs={12} paddingBottom={'25px'}>
        Save changes? This will will make the Housecall Pro integration ACTIVE.
        </Grid>
      </Grid>
    )}
    onConfirm={async (formData) => {
      try {
        onLoading();
        const payload = {
          apiKey: formData.apiKey,
          webhookSecret: formData.webhookSecret,
        };

        if (serviceProviderId) {
          const resp = await saveHCPMaxIntegration(serviceProviderId, payload);

          if (resp.status === 200) {
            createSuccessToast('Housecall Pro Configuration Saved!');
          } else {
            createErrorToast('Error saving Housecall Pro Configuration');
          }
        }
      } catch (error) {
        console.error('error saving HCP config', error);
        createErrorToast('Error saving Housecall Pro Configuration');
      } finally {
        doneLoading();
      }
    }}
  />;
}

export default function HouseCAllProFormInputs() {
  const {} = useProviderIntegrationDetails();
  const { openModal } = useConfirmationModal(houseCallProConfirmationModalKey);
  const formContext = useFormContext();
  const { createErrorToast } = useToast();

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <HookTextField name='apiKey' label='API Key' required />
        </Grid>
        <Grid container item columnGap={1}>
          <Grid item xs={12} md={5}>
            <HookTextField name='webhookSecret' label='Webhook Secret' required />
          </Grid>
        </Grid>
        <Grid item xs={12} paddingTop={'15px'}>
          <PrimaryButton
            type='submit'
            onSubmit={openModal}
            onClick={() => {
              if (formContext.formState.isValid) {
                const formData = formContext.getValues();
                openModal(formData);
              } else {
                createErrorToast('Please fill out all required fields');
              }
            }}
            fullWidth>
                Save Housecall Pro
          </PrimaryButton>
        </Grid>
      </Grid>
      <HouseCallProConfirmationModal />
    </>
  );
}