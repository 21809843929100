import { IconButton } from '@mui/material';
import { PrimaryButton } from 'components/buttons';
import { BigTooltip } from 'components/tooltip/BigTooltip';
import HelpIcon from '@mui/icons-material/Help';
import useLetterBatchDetail from '../hookStore/useLetterBatchDetail';
import { useEffect, useState } from 'react';
import { useLoading } from 'components/Layout/Loading';
import useToast from 'components/toast/useToast';
import { NeighborhoodLetterStatus } from 'model/letter_batch/ancillary';

export default function SendToLettrLabs() {
  const { loadingKey, letterBatchNeighborhoods, onSendToLettrLabs } = useLetterBatchDetail();
  const { createSuccessToast, createErrorToast } = useToast();
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (letterBatchNeighborhoods.every((lbn) => lbn.neighborhoodLetterStatus === NeighborhoodLetterStatus.READY)) {
      setDisabled(false);
    }
  }, [letterBatchNeighborhoods]);
  return (
    <>
      <PrimaryButton
        disabled={disabled}
        type='button'
        onClick={async (e) => {
          onLoading();
          if (!window.confirm('Are you sure you want to send this batch to Lettr Labs?')) {
            doneLoading();
            return;
          }
          await onSendToLettrLabs();
        }}
      >
        Send to Lettr Labs
      </PrimaryButton>
      <BigTooltip title={
        'This button is enabled when all campaigns in the batch are in Ready status.'
      }>
        <IconButton>
          <HelpIcon />
        </IconButton>
      </BigTooltip>
    </>
  );
}