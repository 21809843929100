import { PrimaryButton } from 'components/buttons';
import useConfirmationModal from 'components/modals/useConfirmationModal';
import { INeighborhoodLaunchForm } from 'model/neighborhoodLaunch';
import { SubmitHandler, SubmitErrorHandler, FieldValues, useFormContext } from 'react-hook-form';
import { TerritoryPrepConfirmationModalKey } from './TerritoryPrepConfirmationModal';
import { ITerritoryPrepForm } from 'model/territory_prep/TerritoryPrepForm';
import useTerritoryPrep from '../_hookStore/useTerritoryPrep';

export default function TerritoryPrepSubmitButton() {
  const { openModal } = useConfirmationModal(TerritoryPrepConfirmationModalKey);
  const { handleSubmit, getValues } = useFormContext();
  const { selectedTerritory, validate } = useTerritoryPrep();

  const onSubmit : SubmitHandler<FieldValues> = data => {
    const _isValid = validate(data as ITerritoryPrepForm);
    if (_isValid) {
      openModal(data);
    }
  };

  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    const _isValid = validate(getValues() as ITerritoryPrepForm);
    if (_isValid) {
      openModal(getValues());
    }
  };

  return (
    <PrimaryButton
      sx={{ height: '55px' }}
      disabled={selectedTerritory == null}
      onClick={() => {
        void handleSubmit(onSubmit, onSubmitError)();
      }}
    >
    Prep
    </PrimaryButton>
  );
}