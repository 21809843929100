import { useLoading } from 'components/Layout/Loading';
import createStore from 'hooks/hookStore';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import { findInternalServiceProviderById, getServiceProviderIntegrationDetails } from 'api/serviceProviderApi';
import { IInternalServiceProvider, createEmptyInternalServiceProvider } from 'model/serviceProvider';
import { createEmptyHouseCallProMaxForm, createEmptyServiceTitanForm, IHouseCallProMaxForm, IServiceTitanForm } from 'model/serviceProviderCrmIntegration';


const loadingKey = 'ProviderIntegrationDetailStore';
type ProviderIntegrationDetailStore = {
  serviceProvider: IInternalServiceProvider;
  integrationDetails: any;
  serviceTitanFormData: IServiceTitanForm;
  houseCallProMaxFormData: IHouseCallProMaxForm;
}

const { get, update, registerListener, unregisterListener } = createStore<ProviderIntegrationDetailStore>('ProviderIntegrationDetailStore', {
  serviceProvider: createEmptyInternalServiceProvider(),
  integrationDetails: {},
  serviceTitanFormData: createEmptyServiceTitanForm(),
  houseCallProMaxFormData: createEmptyHouseCallProMaxForm(),
});


export default function useProviderIntegrationDetails() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { serviceProviderId } = useParams();
  const location = useLocation();
  const { createErrorToast, createErrorHandlingToast, createInfoToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      update({
        ...get(),
      });
      unregisterListener(setState);
    };
  }, []);

  async function init() {
    onLoading();
    try {
      let formData = createEmptyInternalServiceProvider();
      let crmIntegrationConfig = {} as any;
      let serviceTitanFormData = createEmptyServiceTitanForm();
      let houseCallProMaxFormData = createEmptyHouseCallProMaxForm();
      if (serviceProviderId) {
        const response = await findInternalServiceProviderById(serviceProviderId);

        if (response.data) {
          formData = response.data;
        } else {
          createErrorToast('Error loading service provider');
        }

        const providerCrmDetails = await getServiceProviderIntegrationDetails(serviceProviderId);
        if (providerCrmDetails.data) {
          crmIntegrationConfig = providerCrmDetails.data?.serviceProvider?.integrationDetails;
        }

      }

      if (crmIntegrationConfig?.serviceTitan) {
        let { tenantId, refreshToken } = crmIntegrationConfig.serviceTitan;
        serviceTitanFormData.tenantId = tenantId;
        if (!!refreshToken) {
          let [clientId, clientSecret] = refreshToken.split(':');
          if (clientId && clientSecret) {
            serviceTitanFormData.clientId = clientId;
            serviceTitanFormData.clientSecret = clientSecret;
          }
        }
      }

      if (crmIntegrationConfig?.housecallPro) {
        let { apiKey, webhookSecret } = crmIntegrationConfig.housecallPro;
        houseCallProMaxFormData.apiKey = apiKey;
        houseCallProMaxFormData.webhookSecret = webhookSecret;
      }

      update({
        ...get(),
        serviceProvider: formData,
        integrationDetails: crmIntegrationConfig,
        serviceTitanFormData: serviceTitanFormData,
        houseCallProMaxFormData: houseCallProMaxFormData,
      });

    } catch (e:any) {
      console.error(e);
    }
    doneLoading(300);
  }


  return {
    ...get(),
    loadingKey,
    init,
  };
}