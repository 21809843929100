import useConfirmationModal from 'components/modals/useConfirmationModal';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { marketDetailsModalKey } from '../marketDetailsForm';
import { PrimaryButton } from 'components/buttons';
import { IMarketForm } from 'model/markets';
import useToast from 'components/toast/useToast';


export default function SubmitButton() {
  const {
    handleSubmit, setError, clearErrors,
    trigger,
    formState: { isSubmitting, isValid, errors }, getValues,
  } = useFormContext();
  const { createErrorToast } = useToast();
  const { openModal } = useConfirmationModal(marketDetailsModalKey);
  const onSubmit : SubmitHandler<IMarketForm> = data => {
    openModal(data);
  };
  const onSubmitError: SubmitErrorHandler<FieldValues> = data => {
    createErrorToast('Some fields are invalid');
  };
  return (
    <PrimaryButton
      disabled={isSubmitting}
      type='button'
      onClick={async (e) => {

        await trigger();
        // @ts-expect-error
        void handleSubmit(onSubmit, onSubmitError)(e);
      }}
    >
            Save
    </PrimaryButton>
  );
}