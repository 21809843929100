import Axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useInterceptor(setToastMessage:any) {
  const [isIntercepting, setIsIntercepting] = useState<boolean>(false);
  const navigate = useNavigate();

  function initializeInterceptor() {
    Axios.interceptors.response.use(function(response) {return response;}, function (error) {
      //should use error.response.status and error.response.data
      if (error.response === undefined) {
        console.error(`request url: ${error.config.url} message: ${error.message}`, error.stack);
      } else if (error.response.status === 401) {
        navigate('/error');
      } else if (error.response.status === 403) {
        const nextMessage = { message: 'You do not have access to this resource.', key: new Date().getTime() };
        setToastMessage((prev) => [...prev, nextMessage]);
      }
      return Promise.reject(error);
    });
    setIsIntercepting(true);
  }
  return {
    isIntercepting,
    initializeInterceptor,
  };
}