import { Grid } from '@mui/material';
import Loading from 'components/Layout/Loading';
import CombinedNeighborhood from './CombinedNeighborhood';
import SubsumedNeighborhoods from './SubsumedNeighborhoods';
import useNeighborhoodCombiner from './useNeighborhoodCombiner';
import SubmitButton from './SubmitButton';
import NeighborhoodMapDisplay from './NeighborhoodMapDisplay';

export default function NeighborhoodCombiner() {
  const { loadingKey } = useNeighborhoodCombiner();
  return (
    <Loading loadingKey={loadingKey}>
      <Grid container flexDirection={'row'} alignItems={'center'} columnSpacing={3} padding={'25px'}>
        <Grid item xs={12} md={3}>
          <CombinedNeighborhood/>
        </Grid>
        <Grid item xs={12} md={3}>
          <SubsumedNeighborhoods/>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid container item xs='auto' sx={{ padding: '20px 0' }} justifyContent='center'>
          <NeighborhoodMapDisplay/>
        </Grid>
        <Grid item xs={12}>
          <SubmitButton/>
        </Grid>
      </Grid>
    </Loading>
  );
}