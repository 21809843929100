export class IntegrationServicePartnerTags {
  static get SERVICETITAN() {
    return 'serviceTitan';
  }
}

export class IntegrationStatus {
  static get PENDING() {
    return 'PENDING';
  }
  static get READY() {
    return 'READY';
  }
  static get ACTIVE() {
    return 'ACTIVE';
  }
  static get DISABLED() {
    return 'DISABLED';
  }

  static list = [
    { id: IntegrationStatus.ACTIVE, name: 'Active' },
    { id: IntegrationStatus.DISABLED, name: 'Disabled' },
    { id: IntegrationStatus.PENDING, name: 'Pending' },
    { id: IntegrationStatus.READY, name: 'Ready' },
  ];

}

export interface IServiceTitanForm {
  tenantId: string;
  clientId: string;
  clientSecret: string;
  status: IntegrationStatus;
}

export const createEmptyServiceTitanForm = (): IServiceTitanForm => ({
  tenantId: '',
  clientId: '',
  clientSecret: '',
  status: IntegrationStatus.PENDING,
});

export interface IHouseCallProMaxForm {
  apiKey: string;
  webhookSecret: string;
}

export const createEmptyHouseCallProMaxForm = (): IHouseCallProMaxForm => ({
  apiKey: '',
  webhookSecret: '',
});