import NeighborhoodMultiAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodMultiAutocomplete';
import useNeighborhoodCombiner from './useNeighborhoodCombiner';
import useToast from 'components/toast/useToast';


export default function SubsumedNeighborhoods() {
  const { setSubsumedNeighborhoodIds } = useNeighborhoodCombiner();
  const { createErrorToast } = useToast();
  return (
    <NeighborhoodMultiAutocomplete
      label={'Subsumed Neighborhoods'}
      formless={true}
      placeholder={'Search and check neighborhoods'}
      required={false}
      postOnChange={(formContext, value, reason, details, rawValues) => {
        if (reason === 'selectOption') {
          // if (!details.option.ancillary.neighborhoodGeometryWKT) {
          //   createErrorToast('Neighborhood does not have geometry data');
          //   formContext.setValue(
          //     'selectedNeighborhoods',
          //     value.filter((v: any) => v !== details.option.optionValue),
          //   );
          //   return;
          // }
        }

        if (value) {
          void setSubsumedNeighborhoodIds(value);
        } else {
          void setSubsumedNeighborhoodIds([]);
        }
      }}
      extraProps={{
        clearOnBlur: false,

      }}
    />
  );
}
