import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import useNeighborhoodCombiner from './useNeighborhoodCombiner';


export default function CombinedNeighborhood() {
  const { setCombinedNeighborhoodId } = useNeighborhoodCombiner();

  return (
    <NeighborhoodAutocomplete
      label={'Consolidating Neighborhood'}
      formless={true}
      placeholder={'Search and check neighborhoods'}
      required={false}
      postOnChange={(formContext, value, reason, details) => {
        if (value) {
          void setCombinedNeighborhoodId(value.ancillary.id);
        } else {
          void setCombinedNeighborhoodId(null);
        }
      }}
      extraProps={{
        clearOnBlur: false,

      }}
    />
  );
}
