import Userfront from '@userfront/react';
import { ReactElement, useEffect, useState } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Outlet, useNavigate } from 'react-router-dom';
import LogoHeader from '../components/Header/logoHeader';

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <div></div>;
};


function Layout () {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!Userfront.tokens.accessToken) {
      navigate('/sign-in');
      return;
    }
  }, []);
  return (
    <Wrapper apiKey={window.REACT_APP_GMAPS_CLIENTSIDE_KEY} render={render} libraries={['drawing']}>
      <div>
        <LogoHeader />
        <main>
          <Outlet />
        </main>
      </div>
    </Wrapper>
  );
}

export default Layout;