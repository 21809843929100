import NeighborhoodMultiAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodMultiAutocomplete';
import useNeighborhoodSplitter from './useNeighborhoodSplitter';


export default function SplitNeighborhoods() {
  const { setSplitNeighborhoodIds } = useNeighborhoodSplitter();

  return (
    <NeighborhoodMultiAutocomplete
      label={'Split Neighborhoods'}
      formless={true}
      placeholder={'Search and check neighborhoods'}
      required={false}
      postOnChange={(formContext, value, reason, details) => {
        if (value) {
          void setSplitNeighborhoodIds(value);
        } else {
          void setSplitNeighborhoodIds([]);
        }
      }}
      extraProps={{
        clearOnBlur: false,

      }}
    />
  );
}
