import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import useSelf from 'hooks/useSelf';
import { ROLE_ADMIN_DEFAULT } from 'model/roles';
import { useParams, useNavigate } from 'react-router-dom';

export default function ViewIntegrationDetailsButton(
) {
  const { serviceProviderId } = useParams();
  const navigate = useNavigate();
  const { roles } = useSelf();
  const hasDefaultAdminRole = roles.indexOf(ROLE_ADMIN_DEFAULT) > -1;
  if (!hasDefaultAdminRole) {
    return <></>;
  }
  return <Button
    variant='contained'
    color='info'
    onClick={() => {
      navigate(`/service-providers/${serviceProviderId}/integration-details`);
    } }
    endIcon={<ArrowForward />}
  >
    Integration Details
  </Button>;
};