import { formatUSDTwoDecimal } from 'util/currencyUtil';
import { formatMonthDayYear } from 'util/dateUtil';
import { formatPercentOneDecimal } from 'util/numberUtil';

export interface IInvoicingTableFilter {
  selectedServiceProviderId: string;
  selectedNeighborhoodId: string;
  selectedMonth: string;
  selectedYear:string;
}

export interface IFinalizedInvoiceDTO {
  invoiceId:string;
  finalizedInvoiceNumber:string;
  success:boolean;
  message:string;
}

export interface IInvoiceCustomerRowDTO {
  id:string;
  providerId:string;
  firstName:string | null;
  lastName:string | null;
  status:string;
  neighborhoodId:string;
  neighborhoodName:string | null;
  serviceType:string | null;
  serviceTypeId:string | null;
  deletedDate:string | null;
  serviceDate:string | null;
  localServiceDate:string | null;
  serviceStartDate:string | null;
  recurring:boolean;
  providerInvoiceDate: string | null;
  invoiceNumber:string | null;
  unitQuantity?: number;
  unitPrice:number | null;
  takeRate:number | null;
  takeRateCap?:number | null;
  totalPrice:number | null;
  totalCommissionAmountToDate?:number | null;
}

export interface IGeneratableInvoiceCustomerRowDTO extends IInvoiceCustomerRowDTO{
  description:string;
}

/**
 * As of May 2023, invoices are only generated at a provider level
 */
export interface IGenerateInvoiceRequest {
  serviceProviderId:string;
  memo:string;
  customers:IGeneratableInvoiceCustomerRowDTO[];
  dueDate:Date | null;
  daysUntilDue: number | null;
  autoFinalizeIfPossible: boolean;
}

export interface IGenerateInvoiceResponse {
  success:boolean;
  errorMessage?:string;
  requestReceivedMessage?:string;
}

export interface ICreateInvoiceItemResultDTO {
  customerId:string;
  success:boolean;
  errorMessage?:string;
}

function convert(data:IInvoiceCustomerRowDTO):IGeneratableInvoiceCustomerRowDTO {
  const description = createInvoiceDescription(data);
  return {
    ...data,
    description,
  };
}

export interface IGenerateInvoiceRequestParams {
  daysUntilDue?: number;
  dueDate?: Date;
}

export function createGenerateInvoiceRequest(
  selectedRows: IInvoiceCustomerRowDTO[],
  memo:string, { daysUntilDue, dueDate }:IGenerateInvoiceRequestParams,
  autoFinalizeIfPossible:boolean,
) :IGenerateInvoiceRequest {
  return {
    serviceProviderId: selectedRows[0].providerId,
    memo: memo,
    customers: selectedRows.map(x => convert(x)),
    daysUntilDue: daysUntilDue ?? null,
    dueDate: dueDate ?? null,
    autoFinalizeIfPossible,
  };
}


export function createInvoiceDescription(data:IInvoiceCustomerRowDTO) {
  let serviceDate:string;

  serviceDate = formatMonthDayYear(data.localServiceDate);
  if (data.recurring) {
    serviceDate = `Started ${formatMonthDayYear(data.serviceStartDate)}`;
  }


  const neighborhoodName = data.neighborhoodName;
  const firstName = data.firstName;
  const lastName = data.lastName;
  const totalPrice = formatUSDTwoDecimal(data.totalPrice);
  const takeRate = formatPercentOneDecimal(data.takeRate);
  return `${serviceDate} - ${neighborhoodName} - ${firstName} ${lastName} (${totalPrice} x ${takeRate}%)`;
}

