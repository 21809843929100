import { Grid, Typography } from '@mui/material';
import HookConfirmationModal from 'components/modals/HookConfirmationModal';
import useTerritoryPrep from '../_hookStore/useTerritoryPrep';

export const TerritoryPrepConfirmationModalKey = 'TerritoryPrepConfirmationModalKey';

export default function TerritoryPrepConfirmationModal() {
  const { selectedTerritory, onConfirmTerritoryPrep } = useTerritoryPrep();
  return (
    <HookConfirmationModal
      title={() => 'Confirm Territory Prep'}
      body='Prep Territory?'
      modalKey={TerritoryPrepConfirmationModalKey}
      message={(confirmArgs, cancelModal) => (
        <Grid container spacing={1} alignItems='center' justifyContent='center' >
          <Grid item xs={12} paddingTop={'30px'} paddingBottom={'25px'}>
            <Typography variant='body1'>
                Initiate preparation process for territory - {selectedTerritory?.optionText}?
            </Typography>
            <Typography variant='body1'>
                This will reconcile NSOs and process staged customers for all neighborhoods in the territory.
            </Typography>
          </Grid>
        </Grid>
      )}
      onConfirm={async (data) => {
        await onConfirmTerritoryPrep(data);
      }}
    />
  );
}