import useToast from 'components/toast/useToast';
import createStore from 'hooks/hookStore';
import { useLoading } from 'components/Layout/Loading';
import { useEffect, useState } from 'react';
import { IDropdownOption } from 'model/dropdown';
import { INeighborhood, IGeoJSONNeighborhood } from 'model/neighborhood';
import { ICombineNeighborhoodRequest } from 'model/neighborhoods/neighborhoodRestructuring';
import { combineNeighborhoods, getNeighborhoodGeoJSON } from 'api/neighborhoodApi';
import { IGeoJSONCoordinates } from 'model/maps';

const loadingKey = 'NeighborhoodCombiner';

type NeighborhoodCombiner = {
  combinedNeighborhoodId: string | null;
  subsumedNeighborhoodIds: string[] | null;
  combinedNeighborhoodGeoJson: IGeoJSONCoordinates | null;
  subsumedNeighborhoodGeoJson: IGeoJSONNeighborhood[];
}

const { get, update, registerListener, unregisterListener } = createStore<NeighborhoodCombiner>('NeighborhoodCombiner', {
  combinedNeighborhoodId: null,
  subsumedNeighborhoodIds: [],
  combinedNeighborhoodGeoJson: null,
  subsumedNeighborhoodGeoJson: [],
});

export default function useNeighborhoodCombiner() {
  const setState = useState(get())[1];
  const { onLoading, doneLoading } = useLoading(loadingKey);
  const { createInfoToast, createErrorToast } = useToast();

  useEffect(() => {
    registerListener(setState);
    return () => {
      unregisterListener(setState);
      update({
        ...get(),
        combinedNeighborhoodId: null,
        subsumedNeighborhoodIds: [],
        combinedNeighborhoodGeoJson: null,
        subsumedNeighborhoodGeoJson: [],
      });
    };
  }, []);

  const setCombinedNeighborhoodId = (neighborhoodId: string | null) => {
    update({
      ...get(),
      combinedNeighborhoodId: neighborhoodId,
    });
  };

  const setSubsumedNeighborhoodIds = (neighborhoodIds: string[]) => {
    update({
      ...get(),
      subsumedNeighborhoodIds: neighborhoodIds,
    });
  };

  async function onSubmit() {
    try {
      onLoading();
      const { combinedNeighborhoodId, subsumedNeighborhoodIds } = get();
      if (!combinedNeighborhoodId || subsumedNeighborhoodIds?.length === 0) {
        createErrorToast('Please select a consolidating neighborhood and at least one subsumed neighborhood.');
        return;
      }
      const request: ICombineNeighborhoodRequest = {
        combinedNeighborhoodId: combinedNeighborhoodId!,
        subsumedNeighborhoodIds: subsumedNeighborhoodIds!,
      };
      const res = await combineNeighborhoods(request);
      if (res.status === 200) {
        createInfoToast('Received request to combine neighborhoods');
      }
    } catch (e) {

    } finally {
      doneLoading(300);
    }
  }

  async function onCombinedNeighborhoodChange(combinedNeighborhoodId:string | null) {
    if (!combinedNeighborhoodId) {
      update({
        ...get(),
        combinedNeighborhoodGeoJson: null,
      });
      return;
    }
    const res = await getNeighborhoodGeoJSON(combinedNeighborhoodId);
    if (res.data) {
      update({
        ...get(),
        combinedNeighborhoodGeoJson: res.data.geoJson ?? null,
      });
    }
  }
  async function onSubsumedNeighborhoodChange(subsumedNeighborhoodIds: string[]) {
    const subsumedNeighborhoodGeoJson:IGeoJSONNeighborhood[] = await Promise.all(
      subsumedNeighborhoodIds.map(async (id) => {
        const res = await getNeighborhoodGeoJSON(id);
        return res.data;
      }));
    update({
      ...get(),
      subsumedNeighborhoodGeoJson,
    });
  }

  return {
    ...get(),
    setCombinedNeighborhoodId,
    setSubsumedNeighborhoodIds,
    onSubmit,
    onCombinedNeighborhoodChange,
    onSubsumedNeighborhoodChange,
    loadingKey,
  };
}