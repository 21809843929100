import Axios, { AxiosResponse } from 'axios';
import { ICloneNeighborhoodForm, INeighborhoodLaunchForm, IProviderCoverageAreaLaunchToolingForm, IReconcileNSOsDTO, IRequestManagementForm } from 'model/neighborhoodLaunch';
import { ITerritoryPrepRequestDTO } from 'model/territory_prep/TerritoryPrepForm';

const apiUrl = window.REACT_APP_BASE_API_URI;

export function launchProviders(values:INeighborhoodLaunchForm) {
  return Axios.post(`${apiUrl}/neighborhoodLaunchTooling/v2/launchProviders`, values);
}

export function launchProvidersByCoverageAreas(values:IProviderCoverageAreaLaunchToolingForm) {
  return Axios.post(`${apiUrl}/neighborhoodLaunchTooling/v2/launchProvidersByCoverageAreas`, values);
}


export function cloneNeighborhood(values:ICloneNeighborhoodForm) {
  return Axios.post(`${apiUrl}/neighborhoodLaunchTooling/v2/cloneNeighborhood`, values);
}

export function deleteClonedNsos(values:IRequestManagementForm) {
  return Axios.delete(`${apiUrl}/neighborhoodLaunchTooling/v2/deleteClonedNsos/${values.requestId}`);
}

/**
 * This will hard delete the NSOs created by a launch provider request
 * @param values
 * @returns
 */
export function deleteProviderLaunch(values:IRequestManagementForm) {
  return Axios.delete(`${apiUrl}/neighborhoodLaunchTooling/v2/deleteProviderLaunch/${values.requestId}`);
}


export function reconcileNSOsByNeighborhood(data: IReconcileNSOsDTO):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/neighborhoodLaunchTooling/v2/reconcileNSOsByNeighborhood`, data);
}

export function reconcileNSOsByServiceProvider(data: IReconcileNSOsDTO):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/neighborhoodLaunchTooling/v2/reconcileNSOsByServiceProvider`, data);
}

export function prepTerritory(territoryId: string, dto: ITerritoryPrepRequestDTO):Promise<AxiosResponse<void>> {
  return Axios.post(`${apiUrl}/neighborhoodLaunchTooling/v2/prepTerritory/${territoryId}`, dto);
}