import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { HookSelect } from 'components/reactHookForm';
import { MetadataConstants } from 'constants/MetadataConstants';
import useErrorState from 'hooks/reactHookForm/useErrorState';
import { StateAbbreviations } from 'model/markets';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { sortByString } from 'util/sortFunctions';
import useMarketDetails from '../hookStore/useMarketDetails';


export default function TimezoneSelect() {
  const formCtx = useFormContext();
  const { timezones } = useMarketDetails();
  const [timezoneData, setTimezones] = useState<any[]>(timezones);
  useEffect(() => {
    setTimezones(timezones);
  }, [timezones]);

  const { isError, getErrorMessage } = useErrorState(formCtx);
  const selectedMarketId = useWatch({ name: 'id' });

  return (
    <FormControl variant='standard' fullWidth error={isError('timezoneId')}>
      <InputLabel htmlFor="TimezoneSelect">Timezone</InputLabel>
      <HookSelect
        rules={{
          required: { value: true, message: 'Required' },
        }}
        disabled={MetadataConstants.UNASSIGNED_MARKET_ID === selectedMarketId}
        control={formCtx.control}
        name='timezoneId'
        id='TimezoneSelect'
        menuItems={timezoneData}
        menuItemKeys={{
          key: 'id',
          value: 'optionValue',
          displayValue: 'optionText',
        }}
      />
      <FormHelperText>{getErrorMessage('timezoneId')}</FormHelperText>
    </FormControl>
  );
}