import { Paper, Grid } from '@mui/material';
import { SecondaryButton } from 'components/buttons';
import SubmitButton from './SubmitButton';
import { useFormContext } from 'react-hook-form';
import { ILetterBatchFormDto } from 'model/letter_batch/letterBatch';
import { useNavigate } from 'react-router-dom';
import ExportButton from './ExportButton';
import MarkSent from './MarkSent';
import SendToLettrLabs from './SendToLettrLabs';


export interface IStickyFormButtonsProps {
  initialFormData: ILetterBatchFormDto;
}

export default function StickyFormButtons({
  initialFormData,
}:IStickyFormButtonsProps) {
  const navigate = useNavigate();
  const formContext = useFormContext();
  return (
    <>
      <Paper
        style={{
          position: 'sticky',
          top: 0,
          padding: '20px 10px 20px 10px',
          zIndex: 1,
          width: '100%',
          boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)',
        }}
        elevation={0}
      >

        <Grid container item xs={12} alignItems='center' justifyContent={'space-between'} >
          <Grid container item xs='auto'>
            <Grid item xs='auto'>
              <ExportButton/>
            </Grid>
            <Grid item xs='auto'>
              <SendToLettrLabs/>
            </Grid>
            <Grid item xs='auto'>
              <MarkSent/>
            </Grid>
          </Grid>
          <Grid container item xs='auto' >
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <SubmitButton/>
            </Grid>
            <Grid item xs='auto' sx={{ marginRight: '10px' }}>
              <SecondaryButton
                onClick={() => {
                  formContext.reset(initialFormData);
                  navigate('/letterBatches');
                }}>
                <>Cancel</>
              </SecondaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}