
import NeighborhoodAutocomplete from 'components/neighborhoodAutocomplete/NeighborhoodAutocomplete';
import useNeighborhoodSplitter from './useNeighborhoodSplitter';

export default function OriginalNeighborhood() {
  const { setOriginalNeighborhoodId } = useNeighborhoodSplitter();

  return (
    <NeighborhoodAutocomplete
      label={'Original Neighborhood (can also appear in the split list)'}
      formless={true}
      placeholder={'Search and check neighborhoods'}
      required={false}
      postOnChange={(formContext, value, reason, details) => {
        if (value) {
          void setOriginalNeighborhoodId(value.ancillary.id);
        } else {
          void setOriginalNeighborhoodId(null);
        }
      }}
      extraProps={{
        clearOnBlur: false,

      }}
    />
  );
}
