import { INeighborhood } from 'model/neighborhood';

export interface IHookCheckboxable {
  checked:boolean;
}

export interface ITerritoryPrepForm {
  territoryNeighborhoods:(INeighborhood & IHookCheckboxable)[];
}

export function createEmptyTerritoryPrepForm() {
  return {
    territoryNeighborhoods: [],
  };
}

export function convertTerritoryPrepFormToDTO(form:ITerritoryPrepForm):ITerritoryPrepRequestDTO {
  return {
    neighborhoodIds: form.territoryNeighborhoods.filter(x => x && x.checked).map(x => x.id!),
  };
}

export interface ITerritoryPrepRequestDTO {
  neighborhoodIds:string[];
}